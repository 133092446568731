import { useState, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Modal,
  Select,
  Form,
  Input,
  InputNumber,
  Tooltip
} from 'antd';
import Icon, { CloseOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import diff from 'diffObj';
import { isEmpty } from 'lodash';

import {
  DELIVERY_STATES_CODES,
  EDIT_ORDER_PACKAGE_TYPES,
  UPDATE_DELIVERY_REMOVED_KEYS
} from 'constants/shipments';
import {
  ORDER_TYPES_VALUE,
  YES_OR_NO_OPTIONS
} from 'constants/shipment-details';
import { getAllPriceSize } from 'services/priceTiers';
import { codRule, noOfItemsRule } from 'utils/forms';
import { editOrder } from 'services/shipments';
import { cleanEmptyString, isUserAuthorized } from 'utils/helpers';
import { COUNTRY_CURRENCY } from 'constants/helper';
import { CUSTOMER_SUPPORT } from 'common/aclMatrix';

import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import Banner from 'components/Banner/Banner';
import { notify } from 'components/Notify/Notify';

import ExceptionStateIcon from 'assets/imgRevamp/ExceptionBannerIcon.svg';
import { ReactComponent as TooltipsIcon } from 'assets/imgRevamp/informationButton.svg';

import './EditDeliveryDetailsModal.less';

const EditDeliveryDetailsModal = ({
  intl,
  visible,
  close,
  delivery,
  onSuccess,
  initialValues,
  allowedToRefund,
  businessDetails
}) => {
  const { Option } = Select;
  const formRef = useRef(null);
  const [pricingSizes, setPricingSizes] = useState([]);
  const [packageTypeValue, setPackageTypeValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOnChangeForm = (name, value) => {
    formRef.current.setFieldsValue({ [name]: value });
  };
  useEffect(() => {
    getPricingSizes();
  }, []);

  const getPricingSizes = async () => {
    try {
      const {
        data: { sizes }
      } = await getAllPriceSize();
      const pricingSizesArray = [];

      sizes.forEach((size) => {
        if (size.alias && size.alias !== size.name) {
          pricingSizesArray.push({
            name: `${size.alias} (${size.name})`,
            value: size.name
          });
        } else {
          pricingSizesArray.push({
            name: size.name,
            value: size.name
          });
        }
      });
      setPricingSizes(pricingSizesArray);
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnFinish = async (values) => {
    setLoading(true);
    const itemSpecs =
      delivery.type === ORDER_TYPES_VALUE.CRP ? 'returnSpecs' : 'specs';

    const changes = diff(initialValues, values);
    const { itemsCount, description, packageType, weight } = changes || {};

    const payload = {
      ...changes,
      ...((itemsCount || description || packageType || weight) && {
        [itemSpecs]: {
          ...((itemsCount || description) && {
            packageDetails: {
              ...(itemsCount && { itemsCount }),
              ...(description && { description })
            }
          }),
          ...(packageType && { packageType }),
          ...(weight && { weight })
        }
      })
    };

    UPDATE_DELIVERY_REMOVED_KEYS.forEach((key) => delete payload[key]);

    if (isEmpty(payload)) {
      close();
      setLoading(false);
      return;
    }

    try {
      await editOrder({
        id: delivery._id,
        updateData: cleanEmptyString(payload)
      });
      notify('Your updates has been saved successfully.', 'success');
      close();
      onSuccess();
    } catch (e) {
      notify(e.message);
    }
    setLoading(false);
  };

  return (
    <Modal
      visible={visible}
      onCancel={close}
      className="br-reschedule-modal__container"
      okText="Update Order"
      okType=""
      okButtonProps={{
        className: 'br-reschdule-modal__primary-button',
        htmlType: 'submit',
        form: 'editDeliveryModalForm',
        loading
      }}
    >
      <div>
        <div className="br-reschedule-modal__header">
          <span>Edit delivery details</span>
          <CloseOutlined onClick={close} />
        </div>
        {delivery?.state?.code === DELIVERY_STATES_CODES.OUT_FOR_DELIVERY && (
          <div className="br-edit-delivery-modal__cod-change-banner">
            <Banner
              subText={intl.formatMessage({
                id: `shipments.new_order.order_details.cod_change_warning`
              })}
              icon={ExceptionStateIcon}
            />
          </div>
        )}

        {initialValues && (
          <Form
            scrollToFirstError
            ref={formRef}
            initialValues={initialValues}
            className="br-edit-delivery-modal__form-container"
            name="editDeliveryModalForm"
            onFinish={handleOnFinish}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="updatingTicketNumber"
                  label={intl.formatMessage({
                    id: 'shipments.new_order.form_placeholder.ticket_number'
                  })}
                >
                  <BRFormInputNumber
                    filedName="updatingTicketNumber"
                    placeholder={intl.formatMessage({
                      id: 'shipments.new_order.form_placeholder.ticket_number'
                    })}
                    handleOnChangeForm={handleOnChangeForm}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              {delivery.type !== ORDER_TYPES_VALUE.CASH_COLLECTION && (
                <Col span={8}>
                  <Form.Item
                    name="itemsCount"
                    label={intl.formatMessage({
                      id: `shipments.new_order.form_labels.number_of_items`
                    })}
                    rules={[
                      {
                        required: true
                      },
                      noOfItemsRule('Number of Items cannot be 0')
                    ]}
                    validateFirst
                  >
                    <BRFormInputNumber
                      maxLength={10}
                      label={intl.formatMessage({
                        id: 'shipments.new_order.form_labels.number_of_items'
                      })}
                      filedName="itemsCount"
                      handleOnChangeForm={handleOnChangeForm}
                      autoComplete={`${Math.random()}`}
                      disabled={isUserAuthorized([CUSTOMER_SUPPORT])}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col
                span={8}
                className={classnames({
                  'br-edit-delivery-modal__cod-input':
                    delivery.type !== ORDER_TYPES_VALUE.CASH_COLLECTION,
                  'br-edit-delivery-modal__cod-input-cash-collection':
                    delivery.type === ORDER_TYPES_VALUE.CASH_COLLECTION
                })}
              >
                <Form.Item
                  name="cod"
                  label="COD"
                  rules={[
                    {
                      required: !!initialValues?.cod
                    },
                    codRule(
                      intl.formatMessage(
                        {
                          id: `shipments.new_order.order_details.cod_greater_than_limit`
                        },
                        {
                          amount:
                            businessDetails?.paymentInfo?.codLimit || 30000
                        }
                      ),
                      delivery?.type === ORDER_TYPES_VALUE.CASH_COLLECTION,
                      businessDetails?.paymentInfo?.codLimit || 30000,
                      allowedToRefund
                    )
                  ]}
                  validateFirst
                >
                  <BRFormInputNumber
                    maxLength={10}
                    label={intl.formatMessage({
                      id: 'shipments.new_order.form_labels.number_of_items'
                    })}
                    filedName="cod"
                    handleOnChangeForm={handleOnChangeForm}
                    autoComplete={`${Math.random()}`}
                    inputProps={{
                      addonAfter: intl.formatMessage(
                        {
                          id: COUNTRY_CURRENCY
                        },
                        { cod: null }
                      ),
                      disabled:
                        (delivery?.type === ORDER_TYPES_VALUE.CRP &&
                          !allowedToRefund) ||
                        delivery?.isFulfillmentOrder ||
                        delivery?.paymentInfo?.transactionId
                    }}
                    acceptFloat
                    acceptNegative={allowedToRefund}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="businessReference"
                  label={intl.formatMessage({
                    id: `shipments.new_order.form_labels.order_reference`
                  })}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: `shipments.new_order.form_placeholder.order_reference_placeholder`
                    })}
                    autoComplete={`${Math.random()}`}
                    maxLength={100}
                    disabled={isUserAuthorized([CUSTOMER_SUPPORT])}
                  />
                </Form.Item>
              </Col>
            </Row>
            {![
              ORDER_TYPES_VALUE.CASH_COLLECTION,
              ORDER_TYPES_VALUE.SIGN_AND_RETURN,
              ORDER_TYPES_VALUE.EXCHANGE
            ].includes(delivery?.type) && (
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="packageType"
                    label={intl.formatMessage({
                      id: `shipments.new_order.form_labels.package_type`
                    })}
                  >
                    <Select
                      onChange={(value) => setPackageTypeValue(value)}
                      disabled={isUserAuthorized([CUSTOMER_SUPPORT])}
                    >
                      {EDIT_ORDER_PACKAGE_TYPES.map((type) => (
                        <Option key={type.value} value={type.value}>
                          {type.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
            {![
              ORDER_TYPES_VALUE.CASH_COLLECTION,
              ORDER_TYPES_VALUE.SIGN_AND_RETURN,
              ORDER_TYPES_VALUE.EXCHANGE
            ].includes(delivery?.type) && (
              <Row>
                <Col span={24}>
                  {delivery?.pricing?.weight ? (
                    <Form.Item
                      name="weight"
                      label={intl.formatMessage({
                        id: `shipments.new_order.order_details.package_weight`
                      })}
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        placeholder={intl.formatMessage({
                          id: 'hubs.receive_new_pickup.pickups_tab_actions.weight_placeholder'
                        })}
                        min={1}
                        step={1}
                        precision={0}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item name="pricingPackageSize" label="Package Size">
                      <Select disabled={isUserAuthorized([CUSTOMER_SUPPORT])}>
                        {pricingSizes?.map((item) => (
                          <Option value={item.value} key={item.name}>
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            )}
            {![
              ORDER_TYPES_VALUE.CASH_COLLECTION,
              ORDER_TYPES_VALUE.SIGN_AND_RETURN,
              ORDER_TYPES_VALUE.CRP
            ].includes(delivery?.type) &&
              !['Light Bulky', 'Heavy Bulky'].includes(
                initialValues.packageType
              ) &&
              !['Light Bulky', 'Heavy Bulky'].includes(packageTypeValue) && (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="allowToOpenPackage"
                      label={
                        <div className="br-edit-delivery-modal__allow-to-open-package">
                          {intl.formatMessage({
                            id: `shipments.new_order.open_package`
                          })}
                          <Tooltip
                            getPopupContainer={(trigger) =>
                              trigger.parentElement.parentElement.parentElement
                                .parentElement
                            }
                            arrowPointAtCenter={true}
                            title={intl.formatMessage({
                              id: `shipments.new_order.tooltips.open_package_tooltip`
                            })}
                          >
                            <Icon component={TooltipsIcon} />
                          </Tooltip>
                        </div>
                      }
                    >
                      <Select options={YES_OR_NO_OPTIONS} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            <Row>
              <Col span={24}>
                <Form.Item
                  name="payWithBostaCredits"
                  label={
                    <div className="br-edit-delivery-modal__allow-to-open-package">
                      {intl.formatMessage({
                        id: `shipments.new_order.form_labels.pay_with_bosta_credits`
                      })}
                    </div>
                  }
                >
                  <Select options={YES_OR_NO_OPTIONS} />
                </Form.Item>
              </Col>
            </Row>
            {delivery.type !== ORDER_TYPES_VALUE.CASH_COLLECTION && (
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label={intl.formatMessage({
                      id: `shipments.new_order.form_labels.package_description`
                    })}
                  >
                    <Input.TextArea
                      maxLength={500}
                      autoSize={{ minRows: 4.1, maxRows: 4.1 }}
                      autoComplete={`${Math.random()}`}
                      disabled={isUserAuthorized([CUSTOMER_SUPPORT])}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24}>
                <Form.Item
                  name="notes"
                  label={intl.formatMessage({
                    id: 'shipments.new_order.customer_details.order_notes'
                  })}
                >
                  <Input.TextArea
                    maxLength={200}
                    autoSize={{ minRows: 4.1, maxRows: 4.1 }}
                    autoComplete={`${Math.random()}`}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default injectIntl(EditDeliveryDetailsModal);
