export const FULFILLMENT_SHEET_COLUMN_LENGTH = 22;
export const NORMAL_SHEET_COLUMN_LENGTH = 26;
export const FXF_MASS_UPLOAD_ACCESS = [
  'SUPER_ADMIN',
  'OPERATIONS_MANAGER',
  'HUB_LEADER',
  'SALES_MANAGER',
  'SALES',
  'ONBOARDING',
  'CUSTOMER_SUPPORT'
];
