import { useIntl } from 'react-intl';
import { Typography } from 'antd';

import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';

import PickupState from 'components/Pickups/components/PickupState/PickupState';
import PickupValidation from 'components/Pickups/components/PickupValidation/PickupValidation';
import InfoCard from './components/InfoCard/InfoCard';

import { ReactComponent as PickupIcon } from 'assets/bosta-icons/Pickups.svg';

import './PickupSummary.less';

const PickupSummary = ({ pickup }) => {
  const intl = useIntl();
  const {
    puid,
    business,
    exception,
    numberOfParcels,
    pickupsCounter,
    deliveries,
    deliveryIds
  } = pickup;

  const businessName = isDefaultBostaCourier([
    ...aclMatrix.THREE_PL,
    VENDOR_MANAGER
  ])
    ? business?.name
    : business?._id;

  const hubReceivedCount = (deliveries || deliveryIds)?.length || undefined;

  return (
    <div className="br-pickup-summary">
      <PickupIcon className="br-pickup-summary__icon" />

      <div className="br-pickup-summary-info">
        <Typography.Title level={4} className="br-pickup-summary-info__title">
          {intl.formatMessage(
            {
              id: 'pickups.pickup_details.summary.title'
            },
            {
              puid
            }
          )}
        </Typography.Title>

        <PickupState item={pickup} />

        <PickupValidation
          exception={exception}
          showValidColor={true}
          useEmptyPlaceholder={false}
        />

        <Typography.Text className="br-pickup-summary-info__business-name">
          {businessName}
        </Typography.Text>
      </div>

      <InfoCard
        title={intl.formatMessage({
          id: 'pickups.pickup_details.summary.business_requested'
        })}
        value={numberOfParcels}
      />
      <InfoCard
        title={intl.formatMessage({
          id: 'pickups.pickup_details.summary.star_received'
        })}
        value={pickupsCounter}
      />
      <InfoCard
        title={intl.formatMessage({
          id: 'pickups.pickup_details.summary.hub_received'
        })}
        value={hubReceivedCount}
      />
    </div>
  );
};

export default PickupSummary;
