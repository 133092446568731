import { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';

import { NUMBER_TEXT } from 'constants/form';
import {
  MAX_ITEM_COD_VALUE,
  MIN_NEGATIVE_COD_VALUE
} from 'constants/shipments';
import { rangeRule } from 'utils/forms';
import { hubUpdateCod } from 'services/hubs';

import { notify } from 'components/Notify/Notify';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import BRButton from 'components/BRButton/BRButton';
import BRFormInputs from 'components/BRFormInputs/BRFormInputs';
import HubManagmentContainer from './HubManagmentContainer';

const UpdateCod = ({ intl }) => {
  const formRef = useRef();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    const { trackingNumber, cod } = values;
    try {
      setLoading(true);
      await hubUpdateCod(trackingNumber, {
        cod
      });
      notify(
        intl.formatMessage({ id: 'hub_management.update_cod.success' }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setLoading(false);
  };
  return (
    <Container
      className="br-tabs-container-content"
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'hub_management.update_cod.title'
          })}
        />
      }
      content={
        <HubManagmentContainer
          children={
            <div className="br-hub-management__update-cod">
              <Form onFinish={handleSubmit} ref={formRef}>
                <BRFormInputs
                  formRef={formRef}
                  name="trackingNumber"
                  label={intl.formatMessage({
                    id: 'hub_management.update_cod.tracking_number'
                  })}
                  type={NUMBER_TEXT}
                  placeholder="123456789"
                />
                <BRFormInputs
                  formRef={formRef}
                  name="cod"
                  label="COD"
                  type={NUMBER_TEXT}
                  acceptNegative={true}
                  placeholder="0.00"
                  rules={[
                    rangeRule({
                      min: MIN_NEGATIVE_COD_VALUE,
                      max: MAX_ITEM_COD_VALUE,
                      message: intl.formatMessage({
                        id: 'hub_management.update_cod.range_error'
                      })
                    })
                  ]}
                />

                <BRButton
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  label="Submit"
                />
              </Form>
            </div>
          }
        />
      }
    />
  );
};

export default injectIntl(UpdateCod);
