import React from 'react';
import XlsxPopulate from 'xlsx-populate';
import * as yup from 'yup';
import { omitBy } from 'lodash';

import { COUNTRIES } from 'constants/country-data';
import { IS_SAUDI } from 'constants/helper';
import {
  FIRST_LINE_MIN_LENGTH,
  MASS_UPLOAD_ERRORS,
  MAX_ITEM_VALUE
} from 'constants/shipments';
import { sendSegment } from 'utils/segment';
import { validatePhoneNumber } from 'utils';

import { intl, fmt } from 'IntlWrapper/IntlWrapper';

const NUMBER_OF_HEADERS_IN_EXCEL = 2;

const ORDER_TYPES = {
  deliver: 'SEND',
  'cash collection': 'CASH_COLLECTION',
  exchange: 'EXCHANGE',
  return: 'CUSTOMER_RETURN_PICKUP',
  sign_and_return: 'SIGN_AND_RETURN'
};

const EXCEL_COLUMNS = {
  FULL_NAME: 0,
  PHONE: 1,
  SECOND_PHONE: 2,
  CITY: 3,
  AREA: 4,
  STREET_NAME: 5,
  BUILDING: 6,
  FLOOR: 7,
  ARAPRTMENT: 8,
  IS_WORK_ADDRESS: 9,
  DELIVERY_NOTES: 10,
  // EMPTY: 11,
  TYPE: 12,
  CASH_AMOUNT: 13,
  NUMBER_OF_ITEMS: 14,
  PACKAGE_DESCRIPTION: 15,
  ORDER_REFERENCE: 16,
  GOODS_VALUE_AMOUNT: 17,
  PAY_WITH_BOSTA_CREDITS: 18,
  ALLOW_OPENEING_PACKAGE: 19,
  // EMPTY: 20,
  RETURN_NUMBER_OF_ITEMS: 21,
  RETURN_PACKAGE_DESCRIPTION: 22,
  // EMPTY: 23,
  PACKAGE_TYPE: 24
  // TRACKING_NUMBER: 25
};

const FXF_EXCEL_COLUMNS = {
  PRODUCT_SKU: 12,
  PRODUCT_QUANTITY: 13,
  CASH_AMOUNT: 14,
  ORDER_REFERENCE: 15,
  ALLOW_OPENEING_PACKAGE: 16
};

const getDistrictAndZone = (area = '') => {
  const districtAndZone = area.split('-');
  if (districtAndZone.length > 1) {
    return {
      districtName: districtAndZone[1]?.toString().trim(),
      zone: districtAndZone[0]?.toString().trim()
    };
  } else {
    return {
      districtName: districtAndZone[0]?.toString().trim(),
      zone: districtAndZone[0]?.toString().trim()
    };
  }
};

const getBooleanValue = (bool) => {
  if (bool?.trim() == null || bool?.trim()?.toLowerCase() === 'no') {
    return false;
  } else if (bool?.trim()?.toLowerCase() === 'yes') {
    return true;
  } else {
    return null;
  }
};

const getOrderType = (orderType, specs) => {
  if (orderType == null) {
    return undefined;
  } else if (
    !ORDER_TYPES[
      orderType?.toString()?.replace(/\s+/g, ' ')?.trim()?.toLowerCase()
    ]
  ) {
    return null;
  } else {
    return specs && specs === 'Sign & Return'
      ? ORDER_TYPES['sign_and_return']
      : ORDER_TYPES[
          orderType?.toString()?.replace(/\s+/g, ' ')?.trim()?.toLowerCase()
        ];
  }
};

const getCODAmount = (cod) => {
  if (cod == null) {
    return null;
  } else if (isNaN(Number(cod?.toString()?.replace(/,/g, '')))) {
    return NaN;
  } else {
    return Number(cod?.toString()?.replace(/,/g, ''));
  }
};

const getPhone = (phone) => {
  if (!phone) {
    return undefined;
  }

  const a2e = phone.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
  const onlyDigits = a2e.replace(/[^+\d+-]/g, '');
  const lastTenDigits = onlyDigits.substring(onlyDigits.length - 10);
  let phoneNumber = onlyDigits;
  if (
    JSON.parse(localStorage.getItem('userInfo'))?.country?.code !==
      COUNTRIES[1].codeName &&
    !phoneNumber.startsWith('+')
  ) {
    phoneNumber = lastTenDigits.startsWith('0')
      ? lastTenDigits
      : `0${lastTenDigits}`;
  }
  return phoneNumber;
};

const constructOrder = (order) => {
  return {
    receiver: {
      fullName: order[EXCEL_COLUMNS.FULL_NAME]?.toString().trim(),
      phone: getPhone(order[EXCEL_COLUMNS.PHONE]?.toString()?.trim()),
      secondPhone: getPhone(
        order[EXCEL_COLUMNS.SECOND_PHONE]?.toString()?.trim()
      )
    },
    dropOffAddress: {
      firstLine: order[EXCEL_COLUMNS.STREET_NAME]?.toString(),
      ...getDistrictAndZone(order[EXCEL_COLUMNS.AREA]),
      city: order[EXCEL_COLUMNS.CITY]?.toString()?.trim(),
      buildingNumber: order[EXCEL_COLUMNS.BUILDING]?.toString(),
      floor: order[EXCEL_COLUMNS.FLOOR]?.toString(),
      apartment: order[EXCEL_COLUMNS.ARAPRTMENT]?.toString(),
      isWorkAddress: getBooleanValue(order[EXCEL_COLUMNS.IS_WORK_ADDRESS])
    },
    specs: {
      packageType:
        order[EXCEL_COLUMNS.PACKAGE_TYPE]?.toString() === 'Sign & Return'
          ? 'Document'
          : order[EXCEL_COLUMNS.PACKAGE_TYPE]?.toString(),
      packageDetails: {
        itemsCount: order[EXCEL_COLUMNS.NUMBER_OF_ITEMS]?.toString(),
        description: order[EXCEL_COLUMNS.PACKAGE_DESCRIPTION]?.toString()
      }
    },
    returnSpecs: {
      packageDetails: {
        itemsCount: order[EXCEL_COLUMNS.RETURN_NUMBER_OF_ITEMS]?.toString(),
        description: order[EXCEL_COLUMNS.RETURN_PACKAGE_DESCRIPTION]?.toString()
      }
    },
    notes: order[EXCEL_COLUMNS.DELIVERY_NOTES]?.toString(),
    type: getOrderType(
      order[EXCEL_COLUMNS.TYPE]?.toString(),
      order[EXCEL_COLUMNS.PACKAGE_TYPE]?.toString()
    ),
    codAmount: getCODAmount(order[EXCEL_COLUMNS.CASH_AMOUNT]?.toString()),
    businessReference: order[EXCEL_COLUMNS.ORDER_REFERENCE]?.toString(),
    allowToOpenPackage: getBooleanValue(
      order[EXCEL_COLUMNS.ALLOW_OPENEING_PACKAGE]
    ),
    trackingNumber: order[EXCEL_COLUMNS?.TRACKING_NUMBER]?.toString(),
    ...(order[EXCEL_COLUMNS?.GOODS_VALUE_AMOUNT] && {
      goodsInfo: {
        amount: order[EXCEL_COLUMNS?.GOODS_VALUE_AMOUNT]?.toString()
      }
    }),
    payWithBostaCredits: getBooleanValue(
      order[EXCEL_COLUMNS.PAY_WITH_BOSTA_CREDITS]
    )
  };
};

const constructFulfillmentOrder = (order) => {
  let dropOffAddress, notes, receiver;
  ({ dropOffAddress, notes, receiver } = constructOrder(order));
  const fxfOrder = {
    fulfillmentInfo: {
      items: [
        {
          bostaSKU: order[FXF_EXCEL_COLUMNS.PRODUCT_SKU]?.toString(),
          quantity: order[FXF_EXCEL_COLUMNS.PRODUCT_QUANTITY]?.toString(),
          itemPrice: getCODAmount(
            order[FXF_EXCEL_COLUMNS.CASH_AMOUNT]?.toString()
          )
        }
      ]
    },
    type: 'FXF_SEND',
    businessReference: order[FXF_EXCEL_COLUMNS.ORDER_REFERENCE]?.toString(),
    allowToOpenPackage: getBooleanValue(
      order[FXF_EXCEL_COLUMNS.ALLOW_OPENEING_PACKAGE]
    )
  };
  return { dropOffAddress, notes, receiver, ...fxfOrder };
};

const schema = (business) =>
  yup.array().of(
    yup.object().shape({
      receiver: yup.object().shape({
        fullName: yup
          .string()
          .label('Full name')
          .required('Full name is required!')
          .max(50, 'Full name should be less than 50 characters'),
        phone: yup
          .string()
          .required('Phone mobile is required!')
          .test({
            name: 'phone validation',
            test: async function (value) {
              const { createError, path } = this;

              const validPhoneNumber = await validatePhoneNumber({
                value,
                required: true,
                phoneNumberRuleProps: {
                  message: '',
                  internationlNumbers: true
                }
              });

              if (!validPhoneNumber) {
                return createError({ path, message: 'Phone is not valid!' });
              }

              return true;
            }
          }),
        secondPhone: yup
          .string()
          .notRequired()
          .test({
            name: 'phone validation',
            test: async function (value) {
              const { createError, path } = this;

              const validPhoneNumber = await validatePhoneNumber({
                value,
                phoneNumberRuleProps: {
                  message: '',
                  landlineNumbers: true
                }
              });

              if (!validPhoneNumber) {
                return createError({
                  path,
                  message: 'Second phone is not valid!'
                });
              }

              return true;
            }
          })
      }),
      dropOffAddress: yup.object().shape({
        firstLine: yup
          .string()
          .label('Street Name')
          .required('Street Name is required!')
          .min(
            FIRST_LINE_MIN_LENGTH,
            'Street name should be more than 10 characters'
          )
          .max(250, 'Street name should be less than 250 characters'),
        districtName: yup.string().label('Area').required('Area is required!'),
        city: yup.string().label('City').required('City is required!'),
        isWorkAddress: yup.mixed().test({
          name: 'is work address validation',
          test: function (value) {
            const { path, createError } = this;
            if (value == null) {
              return createError({
                path,
                message: 'Is work address should be empty, Yes or No.'
              });
            }

            return true;
          }
        })
      }),
      notes: yup
        .string()
        .label('Delivery Notes')
        .max(200, 'Delivery notes should be less than 200 characters'),
      type: yup
        .string()
        .nullable()
        .label('Order type')
        .test({
          name: 'Order type validation',
          test: function (value) {
            const { path, createError } = this;

            if (value === undefined) {
              return createError({
                path,
                message: 'Order type is required!'
              });
            } else if (value === null) {
              return createError({
                path,
                message:
                  'Order type should either be empty or one of the dropdown values'
              });
            }

            return true;
          }
        }),
      codAmount: yup.mixed().test({
        name: 'cash amount validation',
        test: function (value) {
          const { path, parent, createError } = this;

          const codAmount = value;
          const orderType = parent.type;
          const allowedToRefund = business.allowedToRefund;
          const COD_MAX_LIMIT = 30000;

          if (orderType === ORDER_TYPES.deliver) {
            if (codAmount < 0 || isNaN(codAmount)) {
              return createError({
                path,
                message: 'deliver_negative_string'
              });
            }
          } else if (orderType === ORDER_TYPES.exchange) {
            if (allowedToRefund && isNaN(codAmount)) {
              return createError({
                path,
                message: 'exchange_allowed_refund_string'
              });
            } else if (
              !allowedToRefund &&
              (codAmount < 0 || isNaN(codAmount))
            ) {
              return createError({
                path,
                message: 'exchange_not_allowed_refund_negative_string'
              });
            }
          } else if (orderType === ORDER_TYPES.return) {
            if (allowedToRefund && isNaN(codAmount)) {
              return createError({
                path,
                message: 'return_allowed_refund_string'
              });
            } else if (
              !allowedToRefund &&
              (codAmount > 0 || codAmount < 0 || isNaN(codAmount))
            ) {
              return createError({
                path,
                message: 'return_not_allowed_refund_positive_negative_string'
              });
            }
          } else if (orderType === ORDER_TYPES['cash collection']) {
            if (
              allowedToRefund &&
              (codAmount == null || codAmount === 0 || isNaN(codAmount))
            ) {
              return createError({
                path,
                message: 'cash_collection_allowed_refund_empty_zero_string'
              });
            } else if (
              !allowedToRefund &&
              (codAmount == null ||
                codAmount === 0 ||
                codAmount < 0 ||
                isNaN(codAmount))
            ) {
              return createError({
                path,
                message:
                  'cash_collection_not_allowed_refund_empty_zero_negative_string'
              });
            }
          }
          if (codAmount > COD_MAX_LIMIT) {
            return createError({
              path,
              message: 'cod_greater_than_30000'
            });
          }
          return true;
        }
      }),
      goodsInfo: yup
        .object()
        .test({
          test: function () {
            const {
              parent: { type }
            } = this;
            this.options.context = { ...this.options.context, type };

            return true;
          }
        })
        .shape({
          amount: yup.mixed().test({
            name: 'amount',
            test: function (value) {
              const { path, createError, options } = this;
              const { type } = options.context; // Access 'type' from parent-goodsInfo- context
              const amount = parseInt(value);
              const insuranceRank = business?.insurancePlan?.rank;
              console.log({ insuranceRank }, { business });
              const isValueRequired = insuranceRank > 10 && !value;
              const itemValueAllowed = [
                ORDER_TYPES.deliver,
                ORDER_TYPES.exchange,
                ORDER_TYPES.return
              ].includes(type);

              if (itemValueAllowed) {
                if (isValueRequired) {
                  return createError({
                    path,
                    message: 'item_value_required'
                  });
                }
                if (value) {
                  if (isNaN(parseInt(amount)) || amount >= MAX_ITEM_VALUE) {
                    return createError({
                      path,
                      message: 'max_item_value'
                    });
                  }
                }
                return true;
              } else {
                if (value) {
                  return createError({
                    path,
                    message: 'not_allowed_order_type'
                  });
                }
                return true;
              }
            }
          })
        }),
      payWithBostaCredits: yup.mixed().test({
        name: 'Pay with Bosta Credits',
        test: function (value) {
          const { path, createError } = this;
          if (value == null) {
            return createError({
              path,
              message: 'must_be_bool'
            });
          }
          return true;
        }
      }),
      specs: yup.object().shape({
        packageType: yup.mixed().test({
          name: 'package type validation',
          test: function (value) {
            const { path, createError } = this;
            if (
              value != null &&
              ![
                'parcel',
                'document',
                'light bulky',
                'sign & return',
                'heavy bulky'
              ].includes(value?.toString()?.trim()?.toLowerCase())
            ) {
              return createError({
                path,
                message:
                  'Package type should either be empty or one of the dropdown values'
              });
            }

            return true;
          }
        }),
        packageDetails: yup.object().shape({
          itemsCount: yup
            .string()
            .label('Number of items')
            .max(4, 'Number of items should be less than or equal 4 digits'),
          description: yup
            .string()
            .label('Package Description')
            .max(500, 'Package Description should be less than 500 characters')
        })
      }),
      businessReference: yup
        .string()
        .label('Order Reference')
        .max(100, 'Order Reference should be less than 100 characters'),
      allowToOpenPackage: yup.mixed().test({
        name: 'allow open packages validation',
        test: function (value) {
          const { path, createError } = this;
          if (value == null) {
            return createError({
              path,
              message: 'Allow open packages should be empty, Yes or No.'
            });
          }

          return true;
        }
      }),
      returnSpecs: yup.object().shape({
        packageDetails: yup.object().shape({
          itemsCount: yup
            .string()
            .label('Return number of items')
            .max(
              4,
              'Return number of items should be less than or equal 4 digits'
            ),
          description: yup
            .string()
            .label('Return Package Description')
            .max(
              500,
              'Return Package Description should be less than 500 characters'
            )
        })
      }),
      trackingNumber: yup
        .string()
        .label('Tracking Number')
        .test({
          name: 'Tracking Number Validation',
          test: function (value) {
            const { path, createError } = this;

            if (!value) {
              return true;
            }

            value.trim();

            if (value?.length > 13 || value?.length < 9 || isNaN(value)) {
              return createError({
                path,
                message: 'Invalid Tracking Number'
              });
            }

            return true;
          }
        })
    })
  );

const fulfillmentSchema = (business) =>
  yup.array().of(
    yup.object().shape({
      receiver: yup.object().shape({
        fullName: yup
          .string()
          .label('Full name')
          .required('Full name is required!')
          .max(50, 'Full name should be less than 50 characters'),
        phone: yup
          .string()
          .required('Phone mobile is required!')
          .test({
            name: 'phone validation',
            test: async function (value) {
              const { createError, path } = this;

              const validPhoneNumber = await validatePhoneNumber({
                value,
                required: true,
                phoneNumberRuleProps: {
                  message: '',
                  internationlNumbers: true
                }
              });

              if (!validPhoneNumber) {
                return createError({ path, message: 'Phone is not valid!' });
              }

              return true;
            }
          }),
        secondPhone: yup
          .string()
          .notRequired()
          .test({
            name: 'phone validation',
            test: async function (value) {
              const { createError, path } = this;

              const validPhoneNumber = await validatePhoneNumber({
                value,
                phoneNumberRuleProps: {
                  message: '',
                  landlineNumbers: true
                }
              });

              if (!validPhoneNumber) {
                return createError({
                  path,
                  message: 'Second phone is not valid!'
                });
              }

              return true;
            }
          })
      }),
      fulfillmentInfo: yup.object().shape({
        items: yup.array().of(
          yup.object().shape({
            bostaSKU: yup
              .string()
              .label('Product SKU')
              .required('Product SKU is required!'),
            quantity: yup
              .number()
              .test(
                'Product Quantity',
                'Product Quantity should be greater than zero',
                (product_quantity) => product_quantity >= 1
              ),
            itemPrice: yup.mixed().test({
              name: 'cash amount validation',
              test: function (value) {
                const { path, parent, createError } = this;

                const codAmount = value;
                const orderType = parent.type;
                const allowedToRefund = business.allowedToRefund;
                const COD_MAX_LIMIT = 30000;

                if (orderType === ORDER_TYPES.deliver) {
                  if (codAmount < 0 || isNaN(codAmount)) {
                    return createError({
                      path,
                      message: 'deliver_negative_string'
                    });
                  }
                } else if (orderType === ORDER_TYPES.exchange) {
                  if (allowedToRefund && isNaN(codAmount)) {
                    return createError({
                      path,
                      message: 'exchange_allowed_refund_string'
                    });
                  } else if (
                    !allowedToRefund &&
                    (codAmount < 0 || isNaN(codAmount))
                  ) {
                    return createError({
                      path,
                      message: 'exchange_not_allowed_refund_negative_string'
                    });
                  }
                } else if (orderType === ORDER_TYPES.return) {
                  if (allowedToRefund && isNaN(codAmount)) {
                    return createError({
                      path,
                      message: 'return_allowed_refund_string'
                    });
                  } else if (
                    !allowedToRefund &&
                    (codAmount > 0 || codAmount < 0 || isNaN(codAmount))
                  ) {
                    return createError({
                      path,
                      message:
                        'return_not_allowed_refund_positive_negative_string'
                    });
                  }
                } else if (orderType === ORDER_TYPES['cash collection']) {
                  if (
                    allowedToRefund &&
                    (codAmount == null || codAmount === 0 || isNaN(codAmount))
                  ) {
                    return createError({
                      path,
                      message:
                        'cash_collection_allowed_refund_empty_zero_string'
                    });
                  } else if (
                    !allowedToRefund &&
                    (codAmount == null ||
                      codAmount === 0 ||
                      codAmount < 0 ||
                      isNaN(codAmount))
                  ) {
                    return createError({
                      path,
                      message:
                        'cash_collection_not_allowed_refund_empty_zero_negative_string'
                    });
                  }
                }

                if (codAmount > COD_MAX_LIMIT) {
                  return createError({
                    path,
                    message: 'cod_greater_than_30000'
                  });
                }

                return true;
              }
            })
          })
        )
      }),
      dropOffAddress: yup.object().shape({
        firstLine: yup
          .string()
          .label('Street Name')
          .required('Street Name is required!')
          .min(
            FIRST_LINE_MIN_LENGTH,
            'Street name should be more than 10 characters'
          )
          .max(250, 'Street name should be less than 250 characters'),
        districtName: yup.string().label('Area').required('Area is required!'),
        city: yup.string().label('City').required('City is required!'),
        isWorkAddress: yup.mixed().test({
          name: 'is work address validation',
          test: function (value) {
            const { path, createError } = this;
            if (value == null) {
              return createError({
                path,
                message: 'Is work address should be empty, Yes or No.'
              });
            }

            return true;
          }
        })
      }),
      notes: yup
        .string()
        .label('Delivery Notes')
        .max(200, 'Delivery notes should be less than 200 characters'),
      businessReference: yup
        .string()
        .label('Order Reference')
        .max(100, 'Order Reference should be less than 100 characters'),
      allowToOpenPackage: yup.mixed().test({
        name: 'allow open packages validation',
        test: function (value) {
          const { path, createError } = this;
          if (value == null) {
            return createError({
              path,
              message: 'Allow open packages should be empty, Yes or No.'
            });
          }

          return true;
        }
      })
    })
  );

const groupErrors = (errors) => {
  const errorObject = {
    'Full name is required!': { fieldName: 'Full name', rows: [] },
    'Full name should be less than 50 characters': {
      fieldName: 'Full name',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.full_name_less_than_50_characters'
      })
    },
    'Phone mobile is required!': { fieldName: 'Phone', rows: [] },
    'Phone is not valid!': {
      fieldName: 'Phone',
      rows: [],
      error: IS_SAUDI
        ? fmt({
            id: 'orders.mass_upload.error_table.errors.sadui_phone_not_valid'
          })
        : fmt({
            id: 'orders.mass_upload.error_table.errors.phone_not_valid'
          })
    },
    'Second phone is not valid!': {
      fieldName: 'Second phone',
      rows: [],
      error: IS_SAUDI
        ? fmt({
            id: 'orders.mass_upload.error_table.errors.sadui_second_phone_not_valid'
          })
        : fmt({
            id: 'orders.mass_upload.error_table.errors.second_phone_not_valid'
          })
    },
    'Street Name is required!': { fieldName: 'Street Name', rows: [] },
    'Street name should be less than 250 characters': {
      fieldName: 'Street Name',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.street_name_less_than_250_characters'
      })
    },
    'Street name should be more than 10 characters': {
      fieldName: 'Street Name',
      rows: [],
      error: fmt(
        {
          id: 'form.field_min_length'
        },
        {
          label: fmt({
            id: `location_form_labels.street`
          }),
          count: FIRST_LINE_MIN_LENGTH
        }
      )
    },
    'Area is required!': { fieldName: 'Area', rows: [] },
    'City is required!': { fieldName: 'City', rows: [] },
    'Is work address should be empty, Yes or No.': {
      fieldName: 'Work Address?',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.work_address_should_be_empty_yes_or_no'
      })
    },
    'Delivery notes should be less than 200 characters': {
      fieldName: 'Delivery Notes',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.delivery_notes_less_than_100_characters'
      })
    },
    'Order type is required!': { fieldName: 'Order type', rows: [] },
    'Order type should either be empty or one of the dropdown values': {
      fieldName: 'Order type',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.order_type_should_either_be_empty_or_one_of_dropdown_values'
      })
    },
    'Product SKU is required!': {
      fieldName: 'Product SKU',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.field_is_required'
      })
    },
    'Product Quantity should be greater than zero': {
      fieldName: 'Product Quantity',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.quantity_greater_than_0'
      })
    },
    deliver_negative_string: {
      fieldName: 'Cash Amount',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.deliver_negative_string'
      })
    },
    exchange_allowed_refund_string: {
      fieldName: 'Cash Amount',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.exchange_allowed_refund_string'
      })
    },
    exchange_not_allowed_refund_negative_string: {
      fieldName: 'Cash Amount',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.exchange_not_allowed_refund_negative_string'
      })
    },
    return_allowed_refund_string: {
      fieldName: 'Cash Amount',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.return_allowed_refund_string'
      })
    },
    return_not_allowed_refund_positive_negative_string: {
      fieldName: 'Cash Amount',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.return_not_allowed_refund_positive_negative_string'
      })
    },
    cash_collection_allowed_refund_empty_zero_string: {
      fieldName: 'Cash Amount',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.cash_collection_allowed_refund_empty_zero_string'
      })
    },
    cash_collection_not_allowed_refund_empty_zero_negative_string: {
      fieldName: 'Cash Amount',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.cash_collection_not_allowed_refund_empty_zero_negative_string'
      })
    },
    cod_greater_than_30000: {
      fieldName: 'Cash Amount',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.cod_greater_than_30000'
      })
    },
    'Package type should either be empty or one of the dropdown values': {
      fieldName: 'Package Type',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.package_type_should_either_be_empty_or_one_of_dropdown_values'
      })
    },
    'Number of items should be less than or equal 4 digits': {
      fieldName: '#Items',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.number_items_less_than_4'
      })
    },
    'Package Description should be less than 500 characters': {
      fieldName: 'Package Description',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.package_description_less_than_500'
      })
    },
    'Order Reference should be less than 100 characters': {
      fieldName: 'Order Reference',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.order_reference_less_than_100'
      })
    },
    'Allow open packages should be empty, Yes or No.': {
      fieldName: 'Allow Opening Package?',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.allow_open_packages_should_be_empty_yes_or_no'
      })
    },
    'Return number of items should be less than or equal 4 digits': {
      fieldName: 'Return #Items',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.return_number_items_less_than_4'
      })
    },
    'Return Package Description should be less than 500 characters': {
      fieldName: 'Return Package Description',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.return_package_description_less_than_500'
      })
    },
    'Invalid Tracking Number': {
      fieldName: 'Tracking Number',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.invalid_tracking_number'
      })
    },
    not_allowed_order_type: {
      fieldName: 'Goods value',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.not_allowed_order_type'
      })
    },
    item_value_required: {
      fieldName: 'Goods Amount',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.item_value_required'
      })
    },
    max_item_value: {
      fieldName: 'Goods value',
      rows: [],
      error: fmt(
        { id: 'orders.mass_upload.error_table.errors.max_item_value' },
        { max: MAX_ITEM_VALUE }
      )
    },
    must_be_bool: {
      fieldName: 'Pay with Bosta Credits',
      rows: [],
      error: fmt({
        id: 'orders.mass_upload.error_table.errors.must_be_bool'
      })
    }
  };

  errors.forEach((error) => {
    const rowNumber = getRowInSheetIndex(
      Number(error.path.split('.')[0].slice(1, -1))
    );
    errorObject[error.message].rows.push(rowNumber);
  });

  const errorArray = [];
  let keyIndex = 1;
  for (const [key, value] of Object.entries(errorObject)) {
    if (value.rows.length > 0) {
      errorArray.push({
        key: keyIndex++,
        name: value.fieldName,
        issue:
          value.error ||
          fmt({
            id: 'orders.mass_upload.error_table.errors.field_is_required'
          }),
        rows: value.rows.join(', ')
      });
    }
  }

  return errorArray;
};

export const convertExcelToJSON = (
  file,
  selectedBusinessName,
  isFullfillment = false,
  sheetColumnsLength
) => {
  return new Promise((resolve, reject) => {
    XlsxPopulate.fromDataAsync(file).then((workbook) => {
      try {
        sendSegment(
          isFullfillment ? 'FXF_ADMIN_UPLOAD_FILE' : 'E_ADMIN_UPLOAD_FILE',
          {
            'Business Name': selectedBusinessName,
            'Excel Version': workbook?._appProperties?._node?.children
              .find((child) => child.name === 'AppVersion')
              ?.children.find((child) => child != null),
            'Operating System': workbook?._appProperties?._node?.children
              .find((child) => child.name === 'Application')
              ?.children.find((child) => child != null),
            'File Version': workbook?._coreProperties?._node?.children
              .find((child) => child.name === 'dc:title')
              ?.children.find((child) => child != null)
          }
        );
      } catch (error) {
        sendSegment(
          isFullfillment ? 'FXF_ADMIN_UPLOAD_FILE' : 'E_ADMIN_UPLOAD_FILE',
          {
            'Business Name': selectedBusinessName,
            'Excel Version': 'error',
            'Operating System': 'error',
            'File Version': 'error',
            Error: error.message
          }
        );
      }
      try {
        const sheet = workbook.sheet('Add orders here');
        // const sheet = workbook.sheet(4);
        const sheetOrders = sheet
          .usedRange()
          .value()
          .slice(NUMBER_OF_HEADERS_IN_EXCEL);
        sheetColumnsLength(sheetOrders[0].length);
        const orders = sheetOrders
          .filter((row) => Object.keys(omitBy(row, (item) => !item)).length)
          .map((row) =>
            row.map((cell) =>
              cell instanceof XlsxPopulate.RichText
                ? cell.text().replace(/undefined/g, '')
                : cell
            )
          )
          .map(isFullfillment ? constructFulfillmentOrder : constructOrder);
        resolve(orders);
      } catch (error) {
        if (error.message.includes('usedRange')) {
          error.message = 'Old template';
        }
        reject(error);
      }
    });
  });
};

export const validateOrders = (orders, business) => {
  return new Promise(async (resolve, reject) => {
    try {
      await schema(business).validate(orders, { abortEarly: false });
      resolve({ isValid: true });
    } catch (error) {
      resolve({ isValid: false, errors: groupErrors(error.inner) });
    }
  });
};

export const validateFulfillmentOrders = (orders, business) => {
  return new Promise(async (resolve, reject) => {
    try {
      await fulfillmentSchema(business).validate(orders, { abortEarly: false });
      resolve({ isValid: true });
    } catch (error) {
      resolve({ isValid: false, errors: groupErrors(error.inner) });
    }
  });
};

export const getRowInSheetIndex = (index) => {
  // + 1 because array is zero based and sheet is 1 based
  return index + NUMBER_OF_HEADERS_IN_EXCEL + 1;
};

export const shrinkText = (text) => {
  let editedText = '';
  let edited = false;
  let commaFound = 0;
  for (let i = 0; i < text.length; i++) {
    if (text[i] === ',') {
      commaFound++;
      if (commaFound === 5) {
        editedText = text.slice(0, i);
        edited = true;
        return { editedText, edited };
      }
    }
  }

  editedText = text;
  return { editedText, edited };
};

export const massUploadError = (preOrders, errors) => {
  const citiesUncoveredRows = [];
  const citiesUncovered = new Set();
  const citiesNotFoundRows = [];
  const citiesNotFound = new Set();
  const zonesNotFoundRows = [];
  const zonesNotFound = new Set();
  const districtsNotFoundRows = [];
  const districtsNotFound = new Set();
  const trackingNumbersUsedRows = [];
  const trackingNumbersUsed = new Set();
  const wrongSkuRows = [];
  const wrongSku = new Set();
  const invalidSkuQuantityRows = [];
  const uncoveredZoneRows = [];

  for (let i = 0; i < errors.length; i++) {
    const rowIndex = getRowInSheetIndex(errors[i].index);

    if (errors[i].errorMessage === MASS_UPLOAD_ERRORS.UNCOVERED_CITY) {
      citiesUncoveredRows.push(rowIndex);
      citiesUncovered.add(preOrders[errors[i].index].dropOffAddress.city);
    } else if (errors[i].errorMessage === MASS_UPLOAD_ERRORS.CITY_NOT_FOUND) {
      citiesNotFoundRows.push(rowIndex);
      citiesNotFound.add(preOrders[errors[i].index].dropOffAddress.city);
    } else if (errors[i].errorMessage === MASS_UPLOAD_ERRORS.ZONE_NOT_FOUND) {
      zonesNotFoundRows.push(rowIndex);
      zonesNotFound.add(preOrders[errors[i].index].dropOffAddress.zone);
    } else if (
      errors[i].errorMessage === MASS_UPLOAD_ERRORS.DISTRICT_NOT_FOUND
    ) {
      districtsNotFoundRows.push(rowIndex);
      districtsNotFound.add(
        preOrders[errors[i].index].dropOffAddress.districtName
      );
    } else if (
      errors[i].errorMessage === MASS_UPLOAD_ERRORS.TRACKING_NUMBER_ALREADY_USED
    ) {
      trackingNumbersUsedRows.push(rowIndex);
      trackingNumbersUsed.add(preOrders[errors[i].index].trackingNumber);
    } else if (errors[i].errorMessage === MASS_UPLOAD_ERRORS.WRONG_SKU) {
      wrongSkuRows.push(rowIndex);
      wrongSku.add(
        preOrders[errors[i].index].fulfillmentInfo.items[0].bostaSKU
      );
    } else if (
      errors[i].errorMessage === MASS_UPLOAD_ERRORS.INVALID_SKU_QUANTITY
    ) {
      invalidSkuQuantityRows.push(rowIndex);
    } else if (errors[i].errorMessage === MASS_UPLOAD_ERRORS.UNCOVERED_ZONE) {
      uncoveredZoneRows.push(rowIndex);
    }
  }

  //  {key: 1, name: "Street Name", issue: "Field is required", rows: "3"}
  const errorArray = [
    ...(citiesUncoveredRows.length
      ? [
          {
            key: 0,
            name: 'City',
            issue: fmt(
              {
                id: 'orders.mass_upload.error_table.errors.uncovered_city'
              },
              {
                // cityName: Array.from(citiesUncovered).join(', '),
                clickHere: (
                  <a
                    href={`https://bosta.freshdesk.com/a/solutions/articles/36000256735?lang=${intl.locale}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {fmt({
                      id: 'orders.mass_upload.error_table.errors.click_here'
                    })}
                  </a>
                )
              }
            ),
            rows: citiesUncoveredRows.join(', ')
          }
        ]
      : []),
    ...(citiesNotFoundRows.length
      ? [
          {
            key: 1,
            name: 'City',
            issue: fmt({
              id: 'orders.mass_upload.error_table.errors.not_found_city'
            }),
            rows: citiesNotFoundRows.join(', ')
          }
        ]
      : []),
    ...(zonesNotFoundRows.length
      ? [
          {
            key: 2,
            name: 'Area',
            issue: fmt({
              id: 'orders.mass_upload.error_table.errors.not_found_zone'
            }),
            rows: zonesNotFoundRows.join(', ')
          }
        ]
      : []),
    ...(districtsNotFoundRows.length
      ? [
          {
            key: 3,
            name: 'Area',
            issue: fmt({
              id: 'orders.mass_upload.error_table.errors.not_found_district'
            }),
            rows: districtsNotFoundRows.join(', ')
          }
        ]
      : []),
    ...(trackingNumbersUsedRows.length
      ? [
          {
            key: 4,
            name: 'Tracking Number',
            issue: fmt({
              id: 'orders.mass_upload.error_table.errors.tracking_number_already_used'
            }),
            rows: trackingNumbersUsedRows.join(', ')
          }
        ]
      : []),
    ...(wrongSkuRows.length
      ? [
          {
            key: 4,
            name: 'Product SKU',
            issue: fmt({
              id: 'orders.mass_upload.error_table.errors.invalid_sku'
            }),
            rows: wrongSkuRows.join(', ')
          }
        ]
      : []),
    ...(invalidSkuQuantityRows.length
      ? [
          {
            key: 5,
            name: 'Product Quantity',
            issue: fmt({
              id: 'orders.mass_upload.error_table.errors.invalid_sku_quantity'
            }),
            rows: invalidSkuQuantityRows.join(', ')
          }
        ]
      : []),
    ...(uncoveredZoneRows.length
      ? [
          {
            key: 6,
            name: 'Area',
            issue: fmt({
              id: 'orders.mass_upload.error_table.errors.uncovered_zone'
            }),
            rows: uncoveredZoneRows.join(', ')
          }
        ]
      : [])
  ];

  return errorArray;
};
