import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import classNames from 'classnames';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as BusinessesIcon } from 'assets/bosta-icons/Shop.svg';
import { ReactComponent as ValidIcon } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as InvalidIcon } from 'assets/bosta-icons/Circle-clear.svg';

import './BusinessHeader.less';
import { Tooltip } from 'antd';

const BusinessHeader = ({ businessData = {}, loading }) => {
  const intl = useIntl();
  const router = useHistory();
  const {
    _id,
    name,
    status: {
      value: validity,
      invalidationReason,
      duplicateBusinessAccount,
      notSupportedService,
      invalidationNote
    } = {},
    businessTier,
    pricingTier: { name: pricingTier } = {},
    insurancePlan: { name: insurancePlan } = {}
  } = businessData;

  const businessIsValid = validity && validity.toLowerCase() === 'valid';
  const validityIcon = businessIsValid ? <ValidIcon /> : <InvalidIcon />;
  const invalidityMessage =
    !businessIsValid &&
    `${invalidationReason || duplicateBusinessAccount || notSupportedService}${
      invalidationNote ? ` - ${invalidationNote}` : ''
    }`;

  const handleEditBusinessClick = () => {
    router.push(`/businesses/${_id}/edit`);
  };

  return (
    <div className="br-business-header__wrapper">
      <div className="br-business-header__label-container">
        <div className="br-business-header__icon">
          <BusinessesIcon />
        </div>

        <div className="br-business-header__label-title">
          {!loading && (
            <div
              className={classNames({
                'br-business-header__valid': businessIsValid
              })}
            >
              <h2>{name}</h2>{' '}
              <Tooltip title={invalidityMessage}>
                {validityIcon} <span>{validity}</span>
              </Tooltip>
            </div>
          )}

          <h5 className="body-medium">
            {businessTier} {pricingTier && `- ${pricingTier}`}{' '}
            {insurancePlan && `| ${insurancePlan}`}
          </h5>
        </div>
      </div>

      <BRButton
        label={intl.formatMessage({
          id: 'businesses.details.edit_business'
        })}
        type="primary"
        onClick={handleEditBusinessClick}
      />
    </div>
  );
};

export default BusinessHeader;
